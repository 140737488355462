import { DateTime } from "luxon";

/**
 * Default SCP date format
 */
const dateFormat = "dd/MM/yyyy";
const datePostFormat = "yyyy-MM-dd";
//const dateTimePostFormat = "YYYY-MM-DDThh:mm:ssZ";

/**
 * Coverts from ISO string to date format
 */
export const formatISODate = (format: string) => (date?: any) =>
    date ? DateTime.fromISO(date).toFormat(format) : "";

export const formatDate = (format: string) => (date?: any) =>
    date ? date.toFormat(format) : DateTime.now().toFormat(format);

//displayDate("2022-05-10") => 2022/05/10
export const displayDate = formatISODate(dateFormat);

export const isValidDate = (date: string) =>
    typeof date === "string" && DateTime.fromISO(date).isValid;

/**
 * Convert from ISO and use it as MUI picker value
 */
export const datePlaceholder = (date?: string, days?: number) => {
    if (!date) return;

    return days
        ? DateTime.fromISO(date).plus({ days: 1 })
        : DateTime.fromISO(date);
};

/**
 * Convert date for API post request
 */
export const convertDateForAPI = formatDate(datePostFormat);
//export const convertDateTimeForAPI = formatDate(dateTimePostFormat);

export const defaultStartMinDate = () => DateTime.now().minus({ years: 1 });
export const defaultEndMinDate = () =>
    DateTime.now().minus({ years: 1 }).plus({ days: 1 });

export const setInitialDate = (
    action?: string,
    limiter?: { days?: number; years?: number; months?: number },
    format?: string,
) => {
    const defaultDate = DateTime.now();
    if (!action || !limiter)
        return defaultDate.toFormat(format ? format : datePostFormat);
    if (action === "minus") {
        return defaultDate
            .minus(limiter)
            .toFormat(format ? format : datePostFormat);
    }
    if (action === "plus") {
        return defaultDate
            .plus(limiter)
            .toFormat(format ? format : datePostFormat);
    }
};

export const isValidDateRange = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return;

    return DateTime.fromISO(startDate) <= DateTime.fromISO(endDate);
};
