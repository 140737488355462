// Lib
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import clonedeep from "lodash.clonedeep";
import { NumericFormat } from "react-number-format";

import React, { Fragment, useState, useMemo } from "react";

// Own components
import { GrayBox, ProductScales, Select } from "@components";

// Types
import { Brand, Brands, Products, Scale } from "@types";

// Utils
import { isArrayWithContent } from "@utils";

// Style
import * as style from "./style.module.scss";

// Custom hooks
import { useSelectedCountry } from "@hooks";

type EditProductType = {
    index: number;
    product: {
        productId: string;
        productName: string;
        externalProductCode: string;
        sapSkuNo: string;
        brand: {
            name: string;
            id: string;
        };
    };
    priceCondition: { priceCondition: string };
    priceSource: string;
    amount: string;
    type: string;
    scales: Scale[];
};

interface Props {
    onSave?: () => void;
    onChange: (
        product: EditProductType & {
            brand: {
                brandName: string;
                brandId: string;
            };
        },
    ) => void;
    onDelete?: () => void;
    onBlur: (event: React.SyntheticEvent) => void;
    errors?: any;
    touched?: Record<string, boolean>;
    products: Products & { disabledProducts?: Array<string> };
    brands?: Brands;
    brand?: Brand;
    setBrand?: (item: Brand) => void;
    onShowPricesClick: () => void;
    id: string;
    product: EditProductType;
    canDelete?: boolean;
    scales?: Array<Scale>;
    type?: "contractPrice" | "discountPercentage" | "scale";
    amount?: string;
    hasScales?: boolean;
    canSelectPrice?: boolean;
    allowedScaleRange?: { periodFrom: string; periodTo: string };
    onProductChange?: (scales: Scale[]) => void;
    onDiscard?: () => void;
    canDiscard?: boolean;
    categoryType?: string;
    productExternalCodeLabel?: string;
    currency?: string;
}

/**
 * Add product
 */
const AddProduct: React.FC<Props> = ({
    onSave,
    onChange,
    onDelete,
    onBlur,
    errors,
    touched,
    product,
    products,
    onShowPricesClick,
    id,
    canDelete = true,
    scales,
    type,
    amount,
    hasScales = false,
    canSelectPrice = true,
    allowedScaleRange,
    onProductChange,
    onDiscard,
    canDiscard = false,
    categoryType,
    productExternalCodeLabel,
    brands,
    brand,
    setBrand,
    currency,
}) => {
    const { isUkTeam } = useSelectedCountry();

    // Type error state
    const [typeError, setTypeError]: any = useState({
        type: "",
        error: "",
    });

    /**
     * Validate type
     */
    const onProductSave = () => {
        if (!product?.type) setTypeError("Type is mandatory");

        if (
            product?.type === "discountPercentage" &&
            (+product?.amount < 0.1 || +product?.amount > 100)
        ) {
            setTypeError({
                type: "discountPercentage",
                error: "Value must be between 0 and 100",
            });
            return;
        }

        if (product?.type === "contractPrice" && +product?.amount < 0) {
            setTypeError({
                type: "contractPrice",
                error: "Value must be bigger than 0",
            });
            return;
        }
        setTypeError({ type: "", error: "" });
        if (onSave) onSave();
    };

    /**
     * Change product handler
     */
    const handleChange = (key: string, value: any) => {
        const copyProduct = product ? clonedeep(product) : {};

        if (key === "product" && canSelectPrice) {
            copyProduct["priceCondition"] =
                // Fill in the price condition ZGPR for UK team, if price source is SAP and contract is volume based
                hasScales && isUkTeam && product?.priceSource === "SAP"
                    ? "ZGPR"
                    : undefined;
        }

        if (key === "type") {
            copyProduct["amount"] = "";
            !!typeError?.type && setTypeError({ type: "", error: "" });
        }

        copyProduct[key] = value;
        onChange(copyProduct);
    };

    /**
     * Price condition finder
     * mapper for price condition that we handle on the front-end and sent from the back-end
     */
    const priceConditionFinder = useMemo(
        () =>
            typeof product?.priceCondition === "string"
                ? product.priceCondition
                : product?.priceCondition?.priceCondition || "",
        [product],
    );

    /**
     * Check if product valid
     */
    const canSaveProduct = useMemo(() => {
        const hasProductAndAmount =
            !!product?.product?.productId &&
            !!product.type &&
            ((product.type !== "scale" && !!product?.amount) ||
                (product.type === "scale" &&
                    !!isArrayWithContent(product.scales)));

        // Temporary solution for UK, SAP => should priceCondition ZGPR
        const meetTeamConditions =
            isUkTeam && product?.priceSource === "SAP"
                ? priceConditionFinder === "ZGPR"
                : !!priceConditionFinder || !!product?.priceSource;

        return hasProductAndAmount && meetTeamConditions;
    }, [product, isUkTeam, priceConditionFinder]);

    /**
     * Price correction mapper
     */
    const mapPriceCondition = useMemo(() => {
        if (isUkTeam && hasScales) {
            // Volume based contract for UK team
            if (product?.priceSource === "SAP") {
                return priceConditionFinder;
            }
            return product?.priceSource || "";
        }
        return priceConditionFinder || product?.priceSource || "";
    }, [isUkTeam, hasScales, product, priceConditionFinder]);

    return (
        <GrayBox
            padding={"1rem 2rem"}
            header={<Typography variant="h3">Add product</Typography>}
            id={`${id}-add-product`}
        >
            <form
                noValidate
                onBlur={onBlur}
                className={style.form}
                id={`${id}-add-product-form`}
            >
                <Grid
                    item
                    xs={12}
                    container
                    rowSpacing={3}
                    columnSpacing={4}
                    alignItems={"flex-end"}
                >
                    {!!brands && !!setBrand && (
                        <Grid item xs={12} md={6}>
                            <InputLabel
                                error={!!errors?.brand && !!touched?.brand}
                                id={`${id}-select-brand-label`}
                                shrink
                            >
                                {"Brand (*)"}
                            </InputLabel>

                            <Select
                                id={`${id}-select-brand`}
                                value={brand}
                                fullWidth
                                className={style.whiteInput}
                                onChange={(_, index) => {
                                    setBrand(brands?.data[index]);
                                }}
                                disabled={brands?.loading}
                                menuItemLabel={"brandName"}
                                menuItemId="brandId"
                                list={brands.data}
                                loading={brands.loading}
                                name="brand"
                                scrollable
                                error={!!errors?.brand && !!touched?.brand}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={!!errors?.product && !!touched?.product}
                            id={`${id}-select-product-label`}
                            shrink
                        >
                            {"SKU name (*)"}
                        </InputLabel>

                        <Select
                            id={`${id}-select-product`}
                            value={product?.product}
                            fullWidth
                            className={
                                !!brands && !brand?.brandId
                                    ? style.disabledField
                                    : style.whiteInput
                            }
                            onChange={(_, index) => {
                                handleChange("product", products?.data[index]);
                            }}
                            disabled={
                                products?.loading ||
                                (!!brands && !brand?.brandId)
                            }
                            menuItemLabel={"productName"}
                            menuItemId="productId"
                            error={!!errors?.product && !!touched?.product}
                            list={products.data}
                            loading={products.loading}
                            name="product"
                            scrollable
                            disabledItems={products?.disabledProducts}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={!!errors?.products && !!touched?.products}
                            id={`${id}-sku-label`}
                            shrink
                        >
                            {"SKU code"}
                        </InputLabel>
                        <TextField
                            id={`${id}-sku-input`}
                            size="small"
                            autoComplete="off"
                            variant="outlined"
                            disabled={!!brands && !brand?.brandId}
                            className={style.disabledField}
                            inputProps={{ readOnly: true }}
                            fullWidth
                            value={product?.product?.sapSkuNo || ""}
                        />
                    </Grid>

                    <Grid xs={12} md={6} item order={{ xs: 4, md: 3 }}>
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                        >
                            <InputLabel
                                sx={{ paddingTop: "0.5rem" }}
                                error={!!errors?.type && !!touched?.type}
                                id={`${id}-price-condition-label`}
                                shrink
                            >
                                {canSelectPrice
                                    ? "Price condition (*)"
                                    : "Price source"}
                            </InputLabel>

                            {canSelectPrice && (
                                <Button
                                    variant="text"
                                    onClick={onShowPricesClick}
                                    id={`${id}-show-price-conditions-btn`}
                                    disabled={
                                        !product?.product?.sapSkuNo ||
                                        (!!brands && !brand?.brandId)
                                    }
                                >
                                    <Typography
                                        variant="link"
                                        className={style.link}
                                        sx={{
                                            color: !product?.product?.sapSkuNo
                                                ? "text.disabled"
                                                : "",
                                        }}
                                    >
                                        {"Show price table"}
                                    </Typography>
                                </Button>
                            )}
                        </Box>
                        <TextField
                            id={`${id}-price-condition-input`}
                            size="small"
                            variant="outlined"
                            autoComplete="off"
                            className={style.priceCondition}
                            fullWidth
                            inputProps={{ readOnly: true }}
                            value={mapPriceCondition}
                        />
                    </Grid>
                    {!!productExternalCodeLabel &&
                        !!product?.product?.externalProductCode && (
                            <Grid item xs={12} md={6} order={{ xs: 3, md: 4 }}>
                                <InputLabel
                                    error={
                                        !!errors?.products &&
                                        !!touched?.products
                                    }
                                    id={`${id}-externalProductCode-label`}
                                    shrink
                                >
                                    {productExternalCodeLabel}
                                </InputLabel>
                                <TextField
                                    id={`${id}-externalProductCode-input`}
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    className={style.disabledField}
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={
                                        product?.product?.externalProductCode
                                    }
                                />
                            </Grid>
                        )}
                </Grid>

                {hasScales && (
                    <Fragment>
                        <Grid
                            xs={12}
                            item
                            container
                            rowSpacing={3}
                            columnSpacing={4}
                            alignItems={"flex-end"}
                            mb={3}
                            mt={1}
                        >
                            <Grid xs={12} item>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="product-type"
                                        name="scale"
                                        value={type}
                                        onChange={event =>
                                            handleChange(
                                                "type",
                                                event.target.value,
                                            )
                                        }
                                        row
                                    >
                                        <FormControlLabel
                                            value="contractPrice"
                                            control={<Radio size="small" />}
                                            label={
                                                <Typography
                                                    variant="body2"
                                                    color={
                                                        (!!brands &&
                                                            !brand?.brandId) ||
                                                        (!!categoryType &&
                                                            categoryType !==
                                                                "contractPrice")
                                                            ? "text.disabled"
                                                            : "text.primary"
                                                    }
                                                >
                                                    Fixed price
                                                </Typography>
                                            }
                                            disabled={
                                                (!!brands && !brand?.brandId) ||
                                                (!!categoryType &&
                                                    categoryType !==
                                                        "contractPrice")
                                            }
                                        />
                                        <FormControlLabel
                                            value="discountPercentage"
                                            control={<Radio size="small" />}
                                            label={
                                                <Typography
                                                    variant="body2"
                                                    color={
                                                        (!!brands &&
                                                            !brand?.brandId) ||
                                                        (!!categoryType &&
                                                            categoryType !==
                                                                "discountPercentage")
                                                            ? "text.disabled"
                                                            : "text.primary"
                                                    }
                                                >
                                                    Fixed discount
                                                </Typography>
                                            }
                                            disabled={
                                                (!!brands && !brand?.brandId) ||
                                                (!!categoryType &&
                                                    categoryType !==
                                                        "discountPercentage")
                                            }
                                        />
                                        <FormControlLabel
                                            value="scale"
                                            disabled={
                                                !!brands && !brand?.brandId
                                            }
                                            control={<Radio size="small" />}
                                            label={
                                                <Typography
                                                    variant="body2"
                                                    color={
                                                        !!brands &&
                                                        !brand?.brandId
                                                            ? "text.disabled"
                                                            : "text.primary"
                                                    }
                                                >
                                                    Scale
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {(type === "discountPercentage" ||
                                type === "contractPrice") && (
                                <Grid item xs={12} md={6}>
                                    <NumericFormat
                                        id={`${id}-rebate-at-infusion-amount`}
                                        name={"amount"}
                                        className={style.whiteInput}
                                        value={amount}
                                        autoComplete="off"
                                        error={
                                            (!!errors?.amount &&
                                                !!touched?.amount) ||
                                            typeError?.type ===
                                                "discountPercentage" ||
                                            typeError?.type === "contractPrice"
                                        }
                                        disabled={!!brands && !brand?.brandId}
                                        thousandSeparator="."
                                        fullWidth
                                        decimalSeparator=","
                                        customInput={TextField}
                                        allowNegative={false}
                                        size="small"
                                        isAllowed={values => {
                                            const { floatValue } = values;
                                            return !!floatValue &&
                                                type === "discountPercentage"
                                                ? floatValue <= 100
                                                : true;
                                        }}
                                        onValueChange={({ floatValue }) => {
                                            handleChange(
                                                "amount",
                                                floatValue?.toString() ?? "",
                                            );
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Typography variant="caption1">
                                                        {type ===
                                                        "discountPercentage"
                                                            ? "%"
                                                            : currency}
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    {typeError?.error && (
                                        <FormHelperText error>
                                            {typeError.error}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                        {type === "scale" && !!currency && (
                            <ProductScales
                                id={"edit-existing-scale"}
                                scales={scales as Array<Scale>}
                                allowedScaleRange={allowedScaleRange}
                                onProductChange={onProductChange}
                                categoryType={categoryType as string}
                                currency={currency}
                            />
                        )}
                    </Fragment>
                )}
            </form>
            {hasScales && (
                <div className={style.actions}>
                    {canDelete && (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={onDelete}
                            size="large"
                        >
                            <Typography variant="caption1" color={"primary"}>
                                {`Delete product`}
                            </Typography>
                        </Button>
                    )}
                    {canDiscard && (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onDiscard}
                            size="large"
                        >
                            <Typography variant="caption1" color={"primary"}>
                                {`Discard changes`}
                            </Typography>
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onProductSave}
                        size="large"
                        disabled={!canSaveProduct}
                    >
                        <Typography variant="button">{`Save product`}</Typography>
                    </Button>
                </div>
            )}
        </GrayBox>
    );
};
export default React.memo(AddProduct);
