// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";

// Lib
import { DateTime } from "luxon";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import { FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";

// Utils
import { convertDateForAPI, datePlaceholder, isArrayWithContent } from "@utils";

// Own components
import { Table, Modal } from "@components";

// Constants
import { ROWRENDERERCONST, HEADERS } from "@constants";

// Style
import * as style from "../style.module.scss";

// Types
import { Scale } from "@types";

/**
 * Props type
 */
type Props = {
    onProductChange?: (scales: Scale[]) => void;
    id: string;
    scales: Scale[];
    allowedScaleRange?: { periodFrom: string; periodTo: string };
    categoryType: string;
    currency: string;
};

/**
 * Edit scale type
 */
type EditScale = {
    index?: number;
    scale: {
        price?: string;
        discountPercentage?: string;
        unitOfMeasureFrom: string | null;
        unitOfMeasureTo: string | null;
        unitOfMeasure: string;
        periodFrom: string;
        periodTo: string;
    };
};

/**
 * Check for overlap
 */
const hasOverlappingScales = (scales, scaleToCheck, excludedIndex) => {
    if (!scaleToCheck || !isArrayWithContent(scales)) return;

    const luxonStart = DateTime.fromISO(scaleToCheck.periodFrom);
    const luxonEnd = DateTime.fromISO(scaleToCheck.periodTo);

    return scales.some((item, index) => {
        if (excludedIndex == index) return false;

        const luxonRangeStart = DateTime.fromISO(item.periodFrom);
        const luxonRangeEnd = DateTime.fromISO(item.periodTo);

        const dateOverlapping =
            luxonStart <= luxonRangeEnd && luxonRangeStart <= luxonEnd;

        const uomOverlapping =
            +item.unitOfMeasureFrom <= +scaleToCheck.unitOfMeasureTo &&
            +scaleToCheck.unitOfMeasureFrom <= +item.unitOfMeasureTo;

        // Check for overlap
        if (dateOverlapping && uomOverlapping) {
            return true;
        }

        return false;
    });
};

/**
 * Check date validation
 */
const checkDateValidation = (
    scaleToCheck: EditScale["scale"],
    contractStartDate: string,
    contractEndDate: string,
) => {
    if (!scaleToCheck || !contractStartDate) {
        return {
            // return the false one
            invalidStartDate: !!scaleToCheck,
            invalidDateRange: !!contractStartDate,
        };
    }

    const luxonRangeStart = DateTime.fromISO(scaleToCheck.periodFrom);
    const luxonRangeEnd = DateTime.fromISO(scaleToCheck.periodTo);
    const luxonContractStartDate = DateTime.fromISO(contractStartDate);
    const luxonContractEndDate = DateTime.fromISO(contractEndDate);

    const invalidStartDate =
        luxonRangeStart < luxonContractStartDate ||
        luxonRangeStart > luxonContractEndDate ||
        luxonContractEndDate < luxonRangeEnd;

    const invalidDateRange = luxonRangeEnd <= luxonRangeStart;

    return { invalidStartDate, invalidDateRange };
};

/**
 * Product scales
 */
const ProductScales = ({
    id,
    onProductChange,
    scales,
    allowedScaleRange,
    categoryType,
    currency,
}: Props) => {
    // Scale state
    const [editScale, setScale] = useState<EditScale | undefined>(undefined);
    const [uom, setUom] = useState<string>("VOLUME");

    // touched
    const [touched, setTouched] = useState({});

    //Scale error state
    const [scaleError, setScaleError]: any = useState({
        overlap: "",
        invalidDate: "",
    });

    /**
     * Check the type of the contract
     */
    const { scaleType, scaleTypeLabel } = useMemo(() => {
        return {
            scaleTypeLabel:
                categoryType === "discountPercentage" ? "Discount" : "Price",
            scaleType:
                categoryType === "discountPercentage"
                    ? "discountPercentage"
                    : "price",
        };
    }, [categoryType]);

    /**
     * Handle blur
     */
    const onBlur = (key: string) => {
        const copyTouched = { ...touched };
        if (copyTouched[key]) return;
        copyTouched[key] = true;
        setTouched(copyTouched);
    };

    /**
     * Change scale field handler
     */
    const onScaleFieldChange = (key: string, value: any) => {
        if (!editScale) return;

        const copyScale: EditScale = { ...editScale };

        copyScale["scale"][key] = value;
        setScale(copyScale);
    };

    /**
     * Check if the scale valid
     */
    const canSaveScale = useMemo(() => {
        if (!editScale?.scale) return;

        const {
            periodFrom,
            periodTo,
            discountPercentage,
            price,
            unitOfMeasureFrom,
            unitOfMeasureTo,
        } = editScale?.scale as EditScale["scale"];

        return (
            !!periodFrom &&
            !!periodTo &&
            ((!!discountPercentage && +discountPercentage <= 100) || !!price) &&
            !!unitOfMeasureFrom &&
            !!unitOfMeasureTo &&
            +unitOfMeasureFrom < +unitOfMeasureTo
        );
    }, [editScale]);

    useEffect(() => {
        setScale(undefined);
        setUom("VOLUME");
        setTouched({});
    }, []);

    /**
     * Scale action click
     */
    const onScaleActionClick = (
        action: "add" | "delete" | "edit" | "save",
        index?: number,
    ) => {
        if (!onProductChange || !allowedScaleRange) return;
        // ADD
        if (action === "add") {
            setScale({
                scale: {
                    [scaleType]: undefined,
                    unitOfMeasureFrom: null,
                    unitOfMeasureTo: null,
                    unitOfMeasure: uom,
                    periodFrom: allowedScaleRange?.periodFrom,
                    periodTo: allowedScaleRange?.periodTo,
                },
            });
            return;
        }

        const copyScales: Array<Scale> = [...scales];

        // DELETE
        if (action === "delete") {
            const filteredScales = copyScales.filter((_, idx) => idx !== index);
            setScale(undefined);
            onProductChange(filteredScales);
            return;
        }

        // Edit
        if (action === "edit" && index !== undefined) {
            const copyScale = { ...copyScales[index] };
            setScale({
                index,
                scale: copyScale,
            } as EditScale);
            return;
        }
        // SAVE
        if (action === "save" && !!editScale) {
            const copyScale: EditScale["scale"] = editScale?.scale;
            const { invalidStartDate, invalidDateRange } = checkDateValidation(
                copyScale,
                allowedScaleRange?.periodFrom,
                allowedScaleRange?.periodTo,
            );

            if (invalidStartDate) {
                setScaleError({
                    ...scaleError,
                    invalidDate:
                        "Date range must fall within the contract date range.",
                });
                return;
            }

            if (invalidDateRange) {
                setScaleError({
                    ...scaleError,
                    invalidDate:
                        "[Period to] date must be later than [Period from] date",
                });

                return;
            }

            if (isArrayWithContent(scales)) {
                const hasUomOverlapping = hasOverlappingScales(
                    scales,
                    copyScale,
                    editScale.index,
                );

                if (hasUomOverlapping) {
                    setScaleError({
                        ...scaleError,
                        overlap:
                            "Unit of measure or date overlapping is not allowed",
                    });

                    return;
                }
            }

            if (editScale?.index !== undefined) {
                copyScales[editScale.index] = copyScale as any;
            } else {
                copyScales.push(copyScale as any);
            }
            setScaleError({ invalidDate: "", overlap: "" });
        }

        onProductChange(copyScales);
        setScale(undefined);
        setTouched({});
    };

    return (
        <Fragment>
            <Grid item container xs={12} justifyContent={"space-between"}>
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                    my={3}
                >
                    <Grid item xs={6}>
                        <InputLabel
                            id={`${id}-${"unitOfMeasure-label"}`}
                            shrink
                        >
                            {"Unit of measure (*)"}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            name={"unitOfMeasure"}
                            id={`${id}-${"unitOfMeasure"}`}
                            className={style.whiteInput}
                            fullWidth
                            size="small"
                            value={uom}
                            disabled={isArrayWithContent(scales)}
                            onChange={e => setUom(e.target.value)}
                            IconComponent={props => (
                                <KeyboardArrowDownIcon {...props} />
                            )}
                        >
                            {[
                                {
                                    label: "Volume",
                                    value: "VOLUME",
                                },
                                {
                                    label: "Value",
                                    value: "VALUE",
                                },
                            ].map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item container justifyContent={"flex-end"} xs={6}>
                        <Button
                            id={`add-new scale`}
                            variant="text"
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={() => onScaleActionClick("add")}
                            disabled={editScale?.index !== undefined}
                        >
                            Add scale
                        </Button>
                    </Grid>
                </Grid>

                <Table
                    headers={HEADERS.SCALES_WITH_ACTION}
                    rows={scales || []}
                    loading={false}
                    type={ROWRENDERERCONST.SCALES_WITH_ACTION}
                    id={`product-scales-table`}
                    emptyMsg={"No scales found"}
                    minWidth="unset"
                    specificKeys={{ currency }}
                    callbacks={{
                        onEditScale: (
                            action: "edit" | "delete",
                            index: number,
                        ) => onScaleActionClick(action, index),
                    }}
                />
            </Grid>

            <Modal
                open={!!editScale}
                id={`product-scale-modal`}
                title={"Add new scale"}
                onClose={() => {
                    setScale(undefined);
                    setScaleError("");
                }}
                primaryButton={{
                    action: () => onScaleActionClick("save"),
                    text: "Save",
                    disabled: !canSaveScale,
                }}
                secondaryButton={{
                    action: () => {
                        setScale(undefined);
                        setScaleError("");
                    },
                    text: "Cancel",
                }}
            >
                <Grid container item xs={12} px={"24px"} spacing={1}>
                    <Grid item xs={4} pb={1}>
                        <InputLabel
                            id={`${id}-price-condition-label`}
                            shrink
                            error={
                                !!touched[scaleType] &&
                                ((scaleType === "discountPercentage" &&
                                    !!editScale?.scale?.discountPercentage &&
                                    +editScale?.scale?.discountPercentage >
                                        100) ||
                                    (scaleType === "price" &&
                                        !editScale?.scale?.price))
                            }
                        >
                            {`${scaleTypeLabel} (*)`}
                        </InputLabel>

                        <NumericFormat
                            name={"amount"}
                            fullWidth
                            value={editScale?.scale[scaleType]}
                            id={`${id}-${scaleType}`}
                            autoComplete="off"
                            onBlur={() => onBlur(scaleType)}
                            error={
                                !!touched[scaleType] &&
                                ((scaleType === "discountPercentage" &&
                                    !!editScale?.scale?.discountPercentage &&
                                    +editScale?.scale?.discountPercentage >
                                        100) ||
                                    (scaleType === "price" &&
                                        !editScale?.scale?.price))
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            customInput={TextField}
                            allowNegative={false}
                            size="small"
                            isAllowed={values => {
                                const { floatValue } = values;
                                return !!floatValue &&
                                    editScale?.scale["discountPercentage"] !==
                                        undefined
                                    ? floatValue <= 100
                                    : true;
                            }}
                            onValueChange={({ floatValue }) => {
                                onScaleFieldChange(
                                    scaleType,
                                    floatValue?.toString() ?? "",
                                );
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography variant="caption1">
                                            {scaleType === "discountPercentage"
                                                ? "%"
                                                : currency}
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={4} pb={1}>
                        <InputLabel
                            id={`${id}-${"unitOfMeasureFrom-label"}`}
                            shrink
                            error={
                                !!scaleError?.overlap ||
                                (touched["unitOfMeasureFrom"] &&
                                    !editScale?.scale?.unitOfMeasureFrom)
                            }
                        >
                            {"From (*)"}
                        </InputLabel>
                        <NumericFormat
                            name={"unitOfMeasureFrom"}
                            fullWidth
                            value={editScale?.scale?.unitOfMeasureFrom}
                            id={`${id}-${"unitOfMeasureFrom"}`}
                            autoComplete="off"
                            onBlur={() => onBlur("unitOfMeasureFrom")}
                            error={
                                (touched["unitOfMeasureFrom"] &&
                                    (!editScale?.scale?.unitOfMeasureFrom ||
                                        (!!editScale?.scale
                                            ?.unitOfMeasureFrom &&
                                            !!editScale?.scale
                                                ?.unitOfMeasureTo &&
                                            +editScale?.scale
                                                ?.unitOfMeasureFrom >=
                                                +editScale?.scale
                                                    ?.unitOfMeasureTo))) ||
                                !!scaleError?.overlap
                            }
                            helperText={
                                !!editScale?.scale?.unitOfMeasureFrom &&
                                !!editScale?.scale?.unitOfMeasureTo &&
                                +editScale?.scale?.unitOfMeasureFrom >=
                                    +editScale?.scale?.unitOfMeasureTo
                                    ? "Value should not be equal or grater than unit of measure to"
                                    : ""
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={0}
                            customInput={TextField}
                            allowNegative={false}
                            size="small"
                            onValueChange={({ floatValue }) => {
                                onScaleFieldChange(
                                    "unitOfMeasureFrom",
                                    floatValue?.toString() ?? "",
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} pb={1}>
                        <InputLabel
                            id={`${id}-${"unitOfMeasureTo-label"}`}
                            shrink
                            error={
                                !!scaleError?.overlap ||
                                (touched["unitOfMeasureTo"] &&
                                    !editScale?.scale?.unitOfMeasureTo)
                            }
                        >
                            {"To (*)"}
                        </InputLabel>

                        <NumericFormat
                            id={`${id}-${"unitOfMeasureTo"}`}
                            name={"unitOfMeasureTo"}
                            fullWidth
                            value={editScale?.scale?.unitOfMeasureTo}
                            autoComplete="off"
                            onBlur={() => onBlur("unitOfMeasureTo")}
                            error={
                                (touched["unitOfMeasureTo"] &&
                                    (!editScale?.scale?.unitOfMeasureTo ||
                                        (!!editScale?.scale?.unitOfMeasureTo &&
                                            editScale?.scale
                                                ?.unitOfMeasureFrom &&
                                            +editScale?.scale
                                                ?.unitOfMeasureTo <=
                                                +editScale?.scale
                                                    ?.unitOfMeasureFrom))) ||
                                !!scaleError?.overlap
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={0}
                            customInput={TextField}
                            allowNegative={false}
                            size="small"
                            onValueChange={({ floatValue }) => {
                                onScaleFieldChange(
                                    "unitOfMeasureTo",
                                    floatValue?.toString() ?? "",
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={4} pb={1}>
                        <InputLabel
                            id={`${id}-${"periodFrom-label"}`}
                            shrink
                            error={
                                (touched["periodFrom"] &&
                                    !editScale?.scale?.periodFrom) ||
                                !!scaleError?.overlap ||
                                !!scaleError?.invalidDate
                            }
                        >
                            {"Period from (*)"}
                        </InputLabel>
                        <DatePicker
                            value={datePlaceholder(
                                editScale?.scale?.periodFrom,
                            )}
                            onChange={value => {
                                if (!value || !value?.isValid) {
                                    onScaleFieldChange("periodFrom", "");
                                    return;
                                }

                                if (value) {
                                    onScaleFieldChange(
                                        "periodFrom",
                                        convertDateForAPI(value),
                                    );
                                }
                            }}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    sx: {
                                        svg: { color: "#036", opacity: "0.5" },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        (touched["periodFrom"] &&
                                            !editScale?.scale?.periodFrom) ||
                                        !!scaleError?.overlap ||
                                        !!scaleError?.invalidDate,
                                    id: `${id}-${"periodFrom"}`,
                                    name: "periodFrom",
                                    onBlur: () => onBlur("periodFrom"),
                                },
                            }}
                            slots={{
                                // Override default <ActionBar /> with a custom one
                                openPickerIcon: CalendarTodayIcon,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} pb={1}>
                        <InputLabel
                            id={`${id}-${"periodTo-label"}`}
                            shrink
                            error={
                                (touched["periodTo"] &&
                                    !editScale?.scale?.periodTo) ||
                                !!scaleError?.overlap ||
                                !!scaleError?.invalidDate
                            }
                        >
                            {"Period to (*)"}
                        </InputLabel>
                        <DatePicker
                            value={datePlaceholder(editScale?.scale?.periodTo)}
                            onChange={value => {
                                if (!value || !value?.isValid) {
                                    onScaleFieldChange("periodTo", "");
                                    return;
                                }

                                if (value) {
                                    onScaleFieldChange(
                                        "periodTo",
                                        convertDateForAPI(value),
                                    );
                                }
                            }}
                            minDate={datePlaceholder(
                                editScale?.scale?.periodFrom,
                                1,
                            )}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    sx: {
                                        svg: { color: "#036", opacity: "0.5" },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        (touched["periodTo"] &&
                                            !editScale?.scale?.periodTo) ||
                                        !!scaleError?.overlap ||
                                        !!scaleError?.invalidDate,
                                    id: `${id}-${"periodTo"}`,
                                    name: "periodTo",
                                    onBlur: () => onBlur("periodTo"),
                                },
                            }}
                            slots={{
                                // Override default <ActionBar /> with a custom one
                                openPickerIcon: CalendarTodayIcon,
                            }}
                        />
                    </Grid>
                    {(!!scaleError?.invalidDate || !!scaleError?.overlap) && (
                        <Grid item xs={12}>
                            <FormHelperText error>
                                {scaleError?.invalidDate}
                            </FormHelperText>
                            <FormHelperText error>
                                {scaleError?.overlap}
                            </FormHelperText>
                        </Grid>
                    )}
                </Grid>
            </Modal>
        </Fragment>
    );
};

export default React.memo(ProductScales);
