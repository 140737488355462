// icon
import { Add } from "@mui/icons-material";

// Lib
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import clonedeep from "lodash.clonedeep";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { Fragment, useEffect, useState, useMemo } from "react";

// Own components
import {
    GrayBox,
    Modal,
    Table,
    AddProduct,
    FieldRenderer,
    Card,
} from "@components";

// Types
import { Brand, PriceConditionType, Model, Category, Scale } from "@types";

// Utils
import { isArrayWithContent, thousandsSeparator } from "@utils";

// Constants

// Hooks
import { useBrands } from "@hooks";

// Constants
import { ROWRENDERERCONST, HEADERS } from "@constants";

/**
 * Brand type
 */
interface BrandType extends Brand {
    name?: string;
    id?: string;
}

type Product = {
    contractPrice: null | string;
    currency: string;
    discountPercentage: null | string;
    externalProductCode: string | undefined;
    priceCondition: { price: string };
    productId: string;
    productName: string;
    sapSkuNo: string;
    scales: Array<Scale>;
};

type ProductDetailsType = Array<Product>;

/**
 * Props
 */
interface Props {
    hideEdit?: boolean;
    disableEdit?: boolean;
    productsDetails?: ProductDetailsType;
    callbacks?: {
        onEditClick?: (path: string) => void;
        onEditContractItem?: (value: Record<string, any>) => void;
        setDisableSaveButton?: (value: boolean) => void;
    };
    id: string;
    shrinkColumns?: boolean;
    brand: BrandType;
    isEditContract?: boolean;
    generalInformation: any;
    productsList?: {
        load: (brandId: string) => void;
        data: Array<any>;
        loading: boolean;
        error: any;
        disabledProducts?: Array<string>;
    };
    productPrices?: {
        load: (sapSkuNo: string, pricesFrom: string, pricesTo: string) => void;
        data: Array<any>;
        loading: boolean;
        error: any;
    };
    model?: Model;
    contractType?: "VOLUME" | "PERFORMANCE";
    isCreateContract?: boolean;
    categories?: Array<Category>;
    currency: string;
}

const CATEGORY_TYPE_MAPPER = {
    DE_CONTRACT_TYPE_PRICE: "contractPrice",
    DE_CONTRACT_TYPE_PERCENTAGE: "discountPercentage",
    GB_CONTRACT_TYPE_PRICE: "contractPrice",
    GB_CONTRACT_TYPE_PERCENTAGE: "discountPercentage",
};

/**
 * ProductsDetailsSummary
 */
const ProductsDetailsSummary: React.FC<Props> = ({
    productsDetails,
    id,
    hideEdit = false,
    callbacks,
    shrinkColumns,
    brand,
    isEditContract,
    productsList,
    generalInformation,
    productPrices,
    model,
    contractType,
    disableEdit,
    isCreateContract,
    categories,
    currency,
}) => {
    const [showPrices, togglePricesModal] = useState({
        productName: undefined,
        sapSkuNo: undefined,
    });

    const [touched, setTouched] = useState({});
    const [editProduct, setProduct]: any = useState();

    const [selectedBrand, setBrand] = useState<BrandType | undefined>(
        undefined,
    );
    const [scales, setScales] = useState<Scale[]>([]);

    /**
     * Api
     */
    // Brands
    const {
        list: brands,
        loading: loadingBrands,
        error: brandsError,
        load: loadBrands,
    } = useBrands(false);

    const resetProductStates = () => {
        setScales([]);
        setProduct(undefined);
        setBrand(undefined);
        setTouched({});
    };

    /**
     * Category type
     */
    const categoryType = useMemo(() => {
        if (!isArrayWithContent(categories)) return;

        const type = categories?.find(
            cat => CATEGORY_TYPE_MAPPER[cat.categoryId],
        )?.categoryId;
        if (!type) return;
        return CATEGORY_TYPE_MAPPER[type];
    }, [categories]);

    /**
     * Can select price
     * if price source exists & price source is SAP
     *         => user needs to enter it manually
     * if the source is LAUER
     *          => the user can't select any price from price table ( the condition will be set from the model table)
     */
    const canSelectPrice = useMemo(() => {
        if (!isEditContract) return true; // performance based contract

        return model?.priceSource === "SAP";
    }, [model, isEditContract]);

    /**
     * Map product for Add product component
     */
    const onInitializeProduct = (item?: any, index?: number) => {
        if (item && index !== undefined) {
            setProduct({
                index: index,
                product: {
                    productId: item.productId,
                    productName: item.productName,
                    sapSkuNo: item.sapSkuNo,
                    externalProductCode: item.externalProductCode,
                },
                priceCondition: item.priceCondition,
                priceSource: item.priceSource || model?.priceSource,
                amount: item.discountPercentage || item.contractPrice || "",
                type: isArrayWithContent(item.scales)
                    ? "scale"
                    : item.discountPercentage
                      ? "discountPercentage"
                      : item.contractPrice
                        ? "contractPrice"
                        : "",
                scales: item.scales,
                brand: {
                    brandName: item?.brand?.name
                        ? item?.brand?.name
                        : item?.brand?.brandName,
                    brandId: item?.brand?.id
                        ? item?.brand?.id
                        : item?.brand?.brandId,
                },
            });
        } else
            setProduct({
                index: productsDetails?.length,
                product: {},
                priceCondition: canSelectPrice ? {} : undefined,
                priceSource: model?.priceSource,
                amount: undefined,
                type: categoryType,
                scales: [],
            });
    };

    useEffect(() => {
        if (
            !showPrices?.sapSkuNo ||
            !generalInformation ||
            !productPrices?.load
        )
            return;

        productPrices?.load(
            showPrices.sapSkuNo,
            generalInformation.startDate,
            generalInformation.endDate,
        );
    }, [showPrices?.sapSkuNo]);

    /**
     * Initialize product and brand
     */
    useEffect(() => {
        if (!editProduct || editProduct?.index === undefined) {
            isEditContract &&
                !!callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(false);

            return;
        }
        // Load brands
        loadBrands();

        if (editProduct?.product?.productId) {
            // Store brand in local state
            setBrand(editProduct?.brand);
            // Fetch all products that belong to the selected brand
            productsList?.load(editProduct?.brand?.brandId);
        }

        callbacks?.setDisableSaveButton &&
            callbacks?.setDisableSaveButton(true);
    }, [editProduct?.index]);

    /**
     * Initialize product and brand
     * Reset the current product if the user change the brand
     */
    useEffect(() => {
        if (
            !productsList?.load ||
            !selectedBrand ||
            editProduct?.brand?.brandId === selectedBrand?.brandId
        )
            return; // is already initialized

        productsList?.load(selectedBrand?.brandId);

        const copyProductMapper = clonedeep(editProduct);
        copyProductMapper.product = {};

        setProduct(copyProductMapper);
    }, [selectedBrand]);

    /**
     * Handle delete
     */
    const onDelete = (index: number) => {
        if (
            !callbacks?.onEditContractItem ||
            !productsDetails ||
            !isArrayWithContent(productsDetails)
        )
            return;

        const copyProducts = [...productsDetails];
        const filtered = copyProducts.filter((_, idx) => idx !== index);

        callbacks?.onEditContractItem({ products: filtered });
        resetProductStates();
    };

    /**
     * Save product
     */
    const onProductSave = () => {
        if (
            !callbacks?.onEditContractItem ||
            !editProduct?.product?.productId ||
            (!editProduct?.priceCondition && !editProduct?.priceSource)
        )
            return;

        const copyProducts = clonedeep(productsDetails);

        copyProducts[editProduct.index] = {
            productId: editProduct?.product?.productId,
            productName: editProduct?.product?.productName,
            sapSkuNo: editProduct?.product?.sapSkuNo,
            externalProductCode: editProduct?.product?.externalProductCode,
            priceCondition:
                typeof editProduct?.priceCondition === "string"
                    ? editProduct?.priceCondition
                    : { ...editProduct?.priceCondition },
            priceSource: editProduct?.priceSource,
            scales: editProduct.type !== "scale" ? [] : [...editProduct.scales],
            [editProduct.type]:
                editProduct.type === "scale" ? null : editProduct.amount,
            brand: selectedBrand ? { ...selectedBrand } : undefined,
        };

        callbacks?.onEditContractItem({ products: copyProducts });
        resetProductStates();
    };

    /**
     * Change price handler
     */
    const handlePriceChange = (value: any) => {
        if (contractType !== "VOLUME") return;

        const copyProduct = clonedeep(editProduct);
        copyProduct["priceCondition"] = value;
        setProduct(copyProduct);
    };

    /**
     * Can edit products
     */
    const canEditProducts = useMemo(() => {
        if (!contractType) return false;
        return contractType === "VOLUME";
    }, [contractType]);

    /**
     * Handle blur
     */
    const onBlur = (e: React.BaseSyntheticEvent, key?: string) => {
        const copyTouched = { ...touched };
        const name = key ? key : e.target.name;
        if (copyTouched[name]) return;
        Object.assign(copyTouched, {
            [name]: true,
        });

        setTouched(copyTouched);
    };

    /**
     * Map errors
     */
    const mapErrors = useMemo(() => {
        if (!editProduct) return {};
        return {
            amount:
                !editProduct.amount &&
                (editProduct.type === "contractPrice" ||
                    editProduct.type === "discountPercentage"),
            brand: !selectedBrand,
            product: !editProduct.product?.productId,
            type: !editProduct.type,
        };
    }, [editProduct, selectedBrand]);

    /**
     * Disable duplicate product
     */
    const disableSelectedProducts = useMemo(() => {
        if (!isArrayWithContent(productsDetails)) return [];

        return productsDetails?.map((el, idx) => {
            if (idx !== editProduct?.index) return el?.productId;
        });
    }, [productsDetails, editProduct]);

    /**
     * Get subheader
     */
    const getSubheader = (
        product: Product & {
            product?: { sapSkuNo?: string };
            type?: "discountPercentage" | "contractPrice" | "scale";
            amount?: number;
            priceSource?: string;
            priceCondition: string | { priceCondition: string; price: string };
        },
    ) => {
        const sapSkuNo = product?.sapSkuNo || product?.product?.sapSkuNo;
        const priceCondition =
            typeof product?.priceCondition === "string"
                ? product?.priceCondition
                : product?.priceCondition?.priceCondition;

        const price = product?.priceCondition?.price
            ? `${thousandsSeparator(product?.priceCondition?.price)} ${currency}`
            : "";
        const priceSource = product?.priceSource;

        const discount =
            product?.type === "discountPercentage" ||
            !!product?.discountPercentage
                ? `Fixed discount: ${thousandsSeparator(product?.discountPercentage || product?.amount)}%`
                : "";

        const contractPrice =
            product?.type === "contractPrice" || !!product?.contractPrice
                ? `Fixed price: ${thousandsSeparator(product?.contractPrice || product?.amount)} ${currency}`
                : "";

        const scales =
            product?.type === "scale" || isArrayWithContent(product.scales)
                ? "Scales"
                : "";

        return [
            sapSkuNo,
            priceCondition || priceSource,
            price,
            discount || contractPrice || scales,
        ];
    };

    const getHeader = (product: {
        productName?: string;
        product?: { productName?: string };
    }) => {
        const productName =
            product?.product?.productName || product?.productName;
        const brandName = brand?.brandName || brand?.name;

        return (
            <Typography variant="h3">
                {brandName ? `${productName} (${brandName})` : productName}
            </Typography>
        );
    };

    /**
     *
     * @param product {Product}
     * @param index number
     * @returns { onClick: () => void; text: string;}
     */
    const handlePrimaryAction = (product, index) => {
        if (canEditProducts && isEditContract) {
            return {
                text: !isEditContract ? "Edit" : undefined,
                onClick: () => onInitializeProduct(product, index),
                disabled:
                    disableEdit ||
                    (!!editProduct?.productId &&
                        editProduct.productId !== product.productId),
            };
        }

        if (isArrayWithContent(product.scales)) {
            return {
                onClick: () => setScales(product.scales),
                text: "Show scales",
            };
        }

        return undefined;
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Modal
                open={!!showPrices?.productName}
                id={`${id}-contracts-products-prices`}
                title={"Price table"}
                onClose={() =>
                    togglePricesModal({
                        sapSkuNo: undefined,
                        productName: undefined,
                    })
                }
            >
                <div>
                    <Grid container item xs={12} spacing={3} mb={5}>
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-dashboard-products-overview-products-prices-product-name`}
                                label="Product name"
                                value={showPrices?.productName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-dashboard-products-overview-products-prices-product-sku-number`}
                                label="SAP SKU number"
                                value={showPrices?.sapSkuNo}
                            />
                        </Grid>
                    </Grid>

                    <Table
                        headers={HEADERS.PRICES}
                        rows={productPrices?.data || []}
                        loading={productPrices?.loading as boolean}
                        type={ROWRENDERERCONST.PRICES}
                        id={`${id}-products-prices`}
                        emptyMsg={"No data"}
                        callbacks={{
                            selectCondition: (
                                priceCondition: PriceConditionType,
                            ) => {
                                handlePriceChange({
                                    priceCondition: priceCondition?.condition,
                                    currency: priceCondition?.currency,
                                    price: priceCondition?.price,
                                });

                                togglePricesModal({
                                    sapSkuNo: undefined,
                                    productName: undefined,
                                });
                            },
                        }}
                    />
                </div>
            </Modal>

            <Modal
                open={isArrayWithContent(scales)}
                id={`contract-product-scales-overview`}
                title={"Product scales"}
                onClose={() => setScales([])}
            >
                <Table
                    headers={HEADERS.SCALES_OVERVIEW}
                    rows={scales || []}
                    loading={false}
                    type={ROWRENDERERCONST.SCALES_OVERVIEW}
                    id={`${id}-product-scales-table`}
                    emptyMsg={"No scales found"}
                    specificKeys={{ currency }}
                />
            </Modal>

            <Grid item xs={12} md={shrinkColumns ? 6 : 12}>
                <Card
                    title="Product details"
                    id={`${id}-product-details`}
                    action={{
                        hidden:
                            !isCreateContract ||
                            hideEdit ||
                            (isEditContract && contractType === "PERFORMANCE"),
                        disabled: disableEdit,
                        icon: <EditIcon fontSize="small" />,
                        action: () =>
                            !!callbacks?.onEditClick &&
                            callbacks.onEditClick("product-details"),
                    }}
                >
                    {!!productsDetails &&
                        !!isArrayWithContent(productsDetails) &&
                        productsDetails.map((product: any, index) => (
                            <Fragment key={index}>
                                {canEditProducts &&
                                !!isEditContract &&
                                editProduct?.index === index ? (
                                    <AddProduct
                                        id={`${id}-edit-product-form`}
                                        onChange={setProduct}
                                        onDelete={() => onDelete(index)}
                                        onSave={onProductSave}
                                        onBlur={onBlur}
                                        touched={touched}
                                        product={editProduct}
                                        currency={currency}
                                        products={{
                                            data: productsList?.data || [],
                                            loading:
                                                productsList?.loading as boolean,
                                            error: productsList?.error,
                                            disabledProducts:
                                                disableSelectedProducts as string[],
                                        }}
                                        canDelete={productsDetails?.length > 1}
                                        canDiscard
                                        onDiscard={resetProductStates}
                                        brands={{
                                            data: brands?.data,
                                            loading: loadingBrands,
                                            error: brandsError,
                                        }}
                                        setBrand={setBrand}
                                        brand={selectedBrand}
                                        onShowPricesClick={() =>
                                            togglePricesModal({
                                                productName:
                                                    editProduct?.product
                                                        ?.productName,
                                                sapSkuNo:
                                                    editProduct?.product
                                                        ?.sapSkuNo,
                                            })
                                        }
                                        canSelectPrice={canSelectPrice}
                                        type={editProduct?.type}
                                        amount={editProduct?.amount}
                                        scales={editProduct?.scales}
                                        hasScales
                                        allowedScaleRange={{
                                            periodFrom:
                                                generalInformation.startDate,
                                            periodTo:
                                                generalInformation.endDate,
                                        }}
                                        onProductChange={scales =>
                                            setProduct({
                                                ...editProduct,
                                                scales,
                                            })
                                        }
                                        categoryType={categoryType}
                                        errors={mapErrors}
                                    />
                                ) : (
                                    <GrayBox
                                        padding={"1rem"}
                                        marginY={"1rem"}
                                        key={index}
                                        id={`${id}-product-details`}
                                        primaryAction={handlePrimaryAction(
                                            product,
                                            index,
                                        )}
                                        subheaderSplitter={getSubheader(
                                            product,
                                        )}
                                        header={getHeader(product)}
                                    />
                                )}
                            </Fragment>
                        ))}

                    {!!isEditContract && canEditProducts && (
                        <Fragment>
                            {!!isEditContract &&
                                !!productsDetails &&
                                editProduct?.index ===
                                    productsDetails.length && (
                                    <AddProduct
                                        id={`${id}-add-product-form`}
                                        onChange={setProduct}
                                        onDelete={() =>
                                            onDelete(editProduct?.index)
                                        }
                                        touched={touched}
                                        onSave={onProductSave}
                                        product={editProduct}
                                        products={{
                                            data: productsList?.data || [],
                                            loading:
                                                productsList?.loading as boolean,
                                            error: productsList?.error,
                                            disabledProducts:
                                                disableSelectedProducts as string[],
                                        }}
                                        errors={mapErrors}
                                        onBlur={onBlur}
                                        onShowPricesClick={() =>
                                            togglePricesModal({
                                                productName:
                                                    editProduct?.product
                                                        ?.productName,
                                                sapSkuNo:
                                                    editProduct?.product
                                                        ?.sapSkuNo,
                                            })
                                        }
                                        brands={{
                                            data: brands?.data,
                                            loading: loadingBrands,
                                            error: brandsError,
                                        }}
                                        currency={currency}
                                        brand={selectedBrand}
                                        setBrand={setBrand}
                                        canSelectPrice={canSelectPrice}
                                        type={editProduct?.type}
                                        amount={editProduct?.amount}
                                        scales={editProduct?.scales}
                                        hasScales
                                        allowedScaleRange={{
                                            periodFrom:
                                                generalInformation.startDate,
                                            periodTo:
                                                generalInformation.endDate,
                                        }}
                                        onProductChange={scales =>
                                            setProduct({
                                                ...editProduct,
                                                scales,
                                            })
                                        }
                                        categoryType={categoryType}
                                    />
                                )}

                            <GrayBox
                                padding={2}
                                marginY={1}
                                id={`${id}-add-product-btn`}
                                header={
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => onInitializeProduct()}
                                        startIcon={<Add />}
                                        size="large"
                                        disabled={
                                            disableEdit ||
                                            editProduct?.index !== undefined
                                        }
                                    >
                                        <Typography
                                            variant="caption1"
                                            color={
                                                disableEdit ||
                                                editProduct?.index !== undefined
                                                    ? "action.disabled"
                                                    : "primary"
                                            }
                                        >
                                            {`Add product`}
                                        </Typography>
                                    </Button>
                                }
                            />
                        </Fragment>
                    )}
                </Card>
            </Grid>
        </Fragment>
    );
};

export default React.memo(ProductsDetailsSummary);
