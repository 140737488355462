// Lib
import { FormHelperText, Grid, InputLabel, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";

import * as yup from "yup";

// Own components
import { Modal, FieldRenderer } from "@components";

// Utils

type Props = {
    id: string;
    open: boolean;
    onClose: () => void;
    title: string;
    onSubmit: (icdValues: { icdUrl: string; icdNo: string }) => void;
    loading: boolean;
    disabled: boolean;
    oldIcdNo?: string | null;
    oldIcdUrl?: string | null;
};

const URL_SCHEMA = yup.object().shape({
    icdUrl: yup
        .string()
        .url(
            "Invalid URL: Please enter a valid URL that begins with 'https://'",
        )
        .test("is-https", "URL must start with https", value => {
            // Custom validation function to check if the URL starts with https
            if (value) {
                return value.startsWith("https://");
            }
            return true; // Return true for empty values or non-string values
        })
        .nullable(),
});

// Example usage
const validateURL = async url => {
    try {
        await URL_SCHEMA.validate({ icdUrl: url });
        return true;
    } catch (error) {
        return error;
    }
};

/**
 * Change contract ICD
 */
const ChangeContractICD = ({
    id,
    open,
    onClose,
    title,
    onSubmit,
    loading,
    disabled,
    oldIcdUrl,
    oldIcdNo,
}: Props) => {
    // Values state
    const [icdValues, setIcd] = useState<{
        icdUrl: string;
        icdNo: string;
    }>({
        icdNo: "",
        icdUrl: "",
    });

    // Touched state
    const [touched, setTouched] = useState<{
        icdNo: boolean;
        icdUrl: boolean;
    }>({
        icdNo: false,
        icdUrl: false,
    });

    // Error State
    const [urlError, setUrlError] = useState<string>("");

    /**
     * Reset states
     */
    const resetStates = () => {
        setUrlError("");
        setTouched({
            icdNo: false,
            icdUrl: false,
        });
        setIcd({
            icdNo: "",
            icdUrl: "",
        });
    };

    /**
     * Reset states on open
     */
    useEffect(() => {
        if (!open) {
            resetStates();
        }
    }, [open]);

    /**
     * Handle values change
     */
    const onValuesChange = (key: string, value: string) => {
        setIcd({
            ...icdValues,
            [key]: value,
        });

        if (!touched[key]) {
            setTouched({ ...touched, [key]: true });
        }
    };

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        validateURL(icdValues.icdUrl).then(result => {
            if (result instanceof Error) {
                setUrlError(result?.message);
                return;
            } else {
                setUrlError("");
                onSubmit(icdValues);
            }
        });
    };

    /**
     * Render
     */
    return (
        <Modal
            id={`${id}-modal`}
            open={open}
            mediumView
            onClose={onClose}
            title={title}
            primaryButton={{
                action: () => handleSubmit(),
                text: "Submit",
                loading: loading,
                disabled: disabled,
                id: `${id}-primary-btn`,
            }}
            secondaryButton={{
                action: () => {
                    onClose();
                },
                text: "Cancel",
                id: `${id}-secondary-btn`,
            }}
        >
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                    <FieldRenderer
                        id={`old-icd-no`}
                        label="ICD number"
                        value={oldIcdNo || ""}
                    />
                </Grid>

                <Grid item xs={6}>
                    <FieldRenderer
                        id={`old-icd-url`}
                        label="ICD URL"
                        value={oldIcdUrl || ""}
                        hyperlink
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel shrink id={`${id}-icd-number-label`}>
                        {"New ICD number"}
                    </InputLabel>

                    <TextField
                        id={`${id}-icd-number`}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        name="icdNo"
                        value={icdValues?.icdNo}
                        onChange={e => onValuesChange("icdNo", e.target.value)}
                        variant="outlined"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={!!urlError && !!touched?.icdUrl}
                        shrink
                        id={`${id}-icd-url-label`}
                    >
                        {"New ICD URL"}
                    </InputLabel>

                    <TextField
                        id={`${id}-icd-url`}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        name="icdUrl"
                        value={icdValues?.icdUrl}
                        onChange={e => onValuesChange("icdUrl", e.target.value)}
                        variant="outlined"
                        error={!!urlError && !!touched?.icdUrl}
                        disabled={disabled}
                    />
                    {!!urlError && !!touched?.icdUrl && (
                        <FormHelperText error>{urlError}</FormHelperText>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ChangeContractICD;
