//import Libs
import React, { Fragment } from "react";

// Constants
import { ROWRENDERERCONST } from "@constants";

// Types
import { ViewingOption } from "@types";
import { Brand } from "types/api";

import ContractsRow from "./ContractsRow";
import ContractTeamsRow from "./ContractTeamsRow";
import OrdersOverviewRow from "./OrdersOverviewRow";
import OutcomeContractSelectionRow from "./OutcomeContractSelectionRow";
import OutcomeOrderSelectionRow from "./OutcomeOrderSelectionRow";
import OutcomesOverviewRow from "./OutcomesOverviewRow";
import PartnersRow from "./PartnersRow";
import ProductPrices from "./ProductPrices";
import Products from "./Products";
import TasksRow from "./TasksRow";
import FinancesOutcomes from "./FinancesOutcomes";
import BPF from "./BPF";
import TransactionValuesRow from "./TransactionValuesRow";
import InvoiceRelease from "./InvoiceReleaseRow";
import BillingPlanRow from "./BillingPlan";
import MilestoneRow from "./Milestone";
import FinancialConditions from "./FinancialConditions";
import MaintenanceFinancialConditions from "./MaintenanceFinancialConditions";
import ProductsConditions from "./ProductsConditions";
import FinancialTransactions from "./FinancialTransactions";
import RegionRow from "./RegionRow";
import ContextRow from "./ContextRow";
import ExternalCodes from "./ExternalCodes";
import ModelRow from "./ModelRow";
import CategoryRow from "./CategoryRow";
import ContractCategoryRow from "./ContractCategoryRow";
import ScalesRow from "./ScalesRow";
import ClaimRow from "./ClaimRow";
import ClaimContractSelectionRow from "./ClaimContractSelectionRow";
import BPFClaimsSelectionRow from "./BPFClaimsSelectionRow";
import MaintenanceUsersRow from "./MaintenanceUsersRow";
import MaintenanceRoles from "./MaintenanceRoles";
import CreditNotesOutcomeRow from "./CreditNotesOutcomeRow";
import CreditNotesClaimRow from "./CreditNotesClaimRow";

/**
 * Props type
 */
interface Props {
    type: string;
    rows: Array<any>;
    callbacks?: any;
    id: string;
    mapper?: any;
    permissions?: any;
    selectedRow?: string | number | string[];
    viewingOptions?: Array<ViewingOption>;
    brands?: Array<Brand>;
    disabled?: boolean;
    specificKeys?: Record<string, unknown>;
}

const Rows = ({
    type,
    rows,
    callbacks,
    id,
    mapper,
    permissions,
    selectedRow,
    viewingOptions,
    brands,
    disabled,
    specificKeys,
}: Props) => {
    /**
     * Check the visibility of the col
     */
    const canViewCol = (key: string) => {
        if (!viewingOptions) return true;
        const col = viewingOptions.find(el => el.rowKey === key);
        return col ? col.checked : false;
    };

    return (
        <Fragment>
            {rows.map((row, index) => {
                if (type === ROWRENDERERCONST.CONTRACTS) {
                    return (
                        <ContractsRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.CONTRACT_TEAMS) {
                    return (
                        <ContractTeamsRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            callbacks={callbacks}
                            id={id}
                            mapper={mapper}
                            isColVisible={canViewCol}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.ORDERS) {
                    return (
                        <OrdersOverviewRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            permissions={permissions}
                            isColVisible={canViewCol}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.OUTCOMES) {
                    return (
                        <OutcomesOverviewRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            callbacks={callbacks}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.OUTCOMES_FINANCIAL_CONDITIONS) {
                    return (
                        <OutcomesOverviewRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            callbacks={callbacks}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            isOutcomeFinancialConditions
                        />
                    );
                }
                if (type === ROWRENDERERCONST.OUTCOME_CONTRACT_SELECTION) {
                    return (
                        <OutcomeContractSelectionRow
                            key={index}
                            rowIndex={index}
                            callbacks={callbacks}
                            row={row}
                            selectedRow={selectedRow as string}
                            id={id}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.OUTCOME_ORDER_SELECTION) {
                    return (
                        <OutcomeOrderSelectionRow
                            key={index}
                            rowIndex={index}
                            callbacks={callbacks}
                            row={row}
                            selectedRow={selectedRow as string[]}
                            id={id}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.PARTNERS) {
                    return (
                        <PartnersRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            permissions={permissions}
                            isColVisible={canViewCol}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.PRODUCTS) {
                    return (
                        <Products
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            brands={brands}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.PRICES) {
                    return (
                        <ProductPrices
                            id={id}
                            rowIndex={index}
                            key={index}
                            row={row}
                            callbacks={callbacks}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.ONGOING_TASKS) {
                    return (
                        <TasksRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.COMPLETED_TASKS) {
                    return (
                        <TasksRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                            isCompleted
                        />
                    );
                }
                if (type === ROWRENDERERCONST.FINANCES_OUTCOMES) {
                    return (
                        <FinancesOutcomes
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            disabled={disabled}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.BPF) {
                    return (
                        <BPF
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.INVOICE_RELEASE) {
                    return (
                        <InvoiceRelease
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.CREDIT_NOTES_OUTCOME) {
                    return (
                        <CreditNotesOutcomeRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.CREDIT_NOTES_CLAIM) {
                    return (
                        <CreditNotesClaimRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            permissions={permissions}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.TRANSACTION_VALUES) {
                    return (
                        <TransactionValuesRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                            mapper={mapper}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.BILLING_PLAN) {
                    return (
                        <BillingPlanRow
                            key={index}
                            isColVisible={canViewCol}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            disabled={disabled}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.MILESTONE) {
                    return (
                        <MilestoneRow
                            key={index}
                            isColVisible={canViewCol}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.FINANCIAL_CONDITIONS) {
                    return (
                        <FinancialConditions
                            id={id}
                            rowIndex={index}
                            key={index}
                            row={row}
                            callbacks={callbacks}
                        />
                    );
                }

                if (
                    type === ROWRENDERERCONST.MAINTENANCE_FINANCIAL_CONDITIONS
                ) {
                    return (
                        <MaintenanceFinancialConditions
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            isColVisible={canViewCol}
                            callbacks={callbacks}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.PRODUCT_CONDITIONS) {
                    return (
                        <ProductsConditions
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            isColVisible={canViewCol}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.OUTCOME_FINANCIAL_TRANSACTIONS) {
                    return (
                        <FinancialTransactions
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            permissions={permissions}
                            disabled={disabled}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.CONTEXT) {
                    return (
                        <ContextRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            isColVisible={canViewCol}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.REGION) {
                    return (
                        <RegionRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            isColVisible={canViewCol}
                            permissions={permissions}
                        />
                    );
                }
                if (type === ROWRENDERERCONST.EXTERNAL_CODES) {
                    return (
                        <ExternalCodes
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.MODEL) {
                    return (
                        <ModelRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            isColVisible={canViewCol}
                            selectedRow={selectedRow}
                        />
                    );
                }

                if (
                    type === ROWRENDERERCONST.CATEGORY ||
                    type === ROWRENDERERCONST.LINK_CATEGORY
                ) {
                    return (
                        <CategoryRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            isColVisible={canViewCol}
                            selectedRow={selectedRow}
                            linkable={type === ROWRENDERERCONST.LINK_CATEGORY}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.CONTRACT_CATEGORY) {
                    return (
                        <ContractCategoryRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                        />
                    );
                }

                if (
                    type === ROWRENDERERCONST.SCALES_OVERVIEW ||
                    type === ROWRENDERERCONST.SCALES_WITH_ACTION
                ) {
                    return (
                        <ScalesRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            callbacks={callbacks}
                            id={id}
                            specificKeys={specificKeys}
                        />
                    );
                }

                if (
                    type === ROWRENDERERCONST.CLAIM ||
                    type === ROWRENDERERCONST.PRICE_CORRECTION_CLAIMS
                ) {
                    return (
                        <ClaimRow
                            key={index}
                            rowIndex={index}
                            row={row}
                            id={id}
                            callbacks={callbacks}
                            isColVisible={canViewCol}
                            isPriceCorrection={
                                type ===
                                ROWRENDERERCONST.PRICE_CORRECTION_CLAIMS
                            }
                            permissions={permissions}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.CLAIM_CONTRACT) {
                    return (
                        <ClaimContractSelectionRow
                            key={index}
                            rowIndex={index}
                            callbacks={callbacks}
                            row={row}
                            selectedRow={selectedRow as string}
                            id={id}
                            disabled={disabled}
                        />
                    );
                }

                if (
                    type === ROWRENDERERCONST.BPF_CLAIMS ||
                    type === ROWRENDERERCONST.BPF_PRICE_CORRECTION_CLAIMS
                ) {
                    return (
                        <BPFClaimsSelectionRow
                            key={index}
                            rowIndex={index}
                            callbacks={callbacks}
                            row={row}
                            isPriceCorrectionClaims={
                                type ===
                                ROWRENDERERCONST.BPF_PRICE_CORRECTION_CLAIMS
                            }
                            selectedRow={selectedRow as string}
                            id={id}
                            disabled={disabled}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.CLAIM_FINANCIAL_TRANSACTIONS) {
                    return (
                        <BPFClaimsSelectionRow
                            key={index}
                            rowIndex={index}
                            callbacks={callbacks}
                            row={row}
                            selectedRow={selectedRow as string}
                            id={id}
                            disabled={disabled}
                            isFinancialTransaction
                        />
                    );
                }

                if (type === ROWRENDERERCONST.MAINTENANCE_USERS) {
                    return (
                        <MaintenanceUsersRow
                            key={index}
                            rowIndex={index}
                            callbacks={callbacks}
                            row={row}
                            id={id}
                            disabled={disabled}
                        />
                    );
                }

                if (type === ROWRENDERERCONST.MAINTENANCE_ROLES) {
                    return (
                        <MaintenanceRoles
                            key={index}
                            rowIndex={index}
                            callbacks={callbacks}
                            row={row}
                            id={id}
                            disabled={disabled}
                        />
                    );
                }
                return null;
            })}
        </Fragment>
    );
};

export default React.memo(Rows);
